'use client';

import { useApiError } from './useApiError';
import { useCallback, useEffect } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { UserType } from 'database';
import { LoginUserResponseBody, LoginWithIdpSchema } from '@repo/bff';
import { WretchError } from 'wretch/resolver';
import { useToast } from '../../../packages/ui/src/components/ui/use-toast';

export const useGoogleAuth = ({
  onLoginSuccess,
  handleResponse,
  isLoading,
  setIsLoading,
}: {
  onLoginSuccess: (data: {
    body: LoginWithIdpSchema;
  }) => Promise<LoginUserResponseBody>;
  handleResponse: (res: { user_type: UserType }) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) => {
  const { handleError } = useApiError();

  const { data: session, status } = useSession();

  const { toast } = useToast();

  const handleOnLogin = useCallback(async () => {
    if (!session) {
      return;
    }

    const { accessToken, expires } = session;

    const isExpired = new Date(expires).getTime() < new Date().getTime();

    if (accessToken && isExpired) {
      setIsLoading(false);
      return await signOut();
    }

    try {
      const res = await onLoginSuccess({
        body: {
          access_token: accessToken,
          provider: 'google',
        },
      });
      console.log(res);

      return handleResponse(res);
    } catch (error: unknown) {
      if (error instanceof WretchError) {
        if (error.json.code === 'not_allowed_doctor') {
          toast({
            title: 'Proximamente se habilitará el acceso para médicos',
            variant: 'default',
          });
        } else {
          handleError(error);
        }
      }

      setIsLoading(false);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return await signOut();
    }
  }, [session]);

  useEffect(() => {
    switch (status) {
      case 'loading': {
        if (!isLoading) {
          setIsLoading(true);
        }
        break;
      }

      case 'authenticated': {
        handleOnLogin();
        break;
      }

      case 'unauthenticated': {
        if (isLoading) {
          setIsLoading(false);
        }
        break;
      }
    }
  }, [status, isLoading]);

  return {
    isLoading,
    setIsLoading,
    status,
  };
};
