'use client';

import { useLanguage } from '@repo/i18n';
import { useRouter } from 'next/navigation';
import { UserType } from 'database';
import { useGoogleAuth } from '../hooks/useGoogleAuth';
import { LoginUserResponseBody, LoginWithIdpSchema } from '@repo/bff';
import { Spinner } from '@repo/ui/components/ui/Spinner';

export const GoogleAuthLayout = ({
  children,
  onLoginSuccess,
  isLoading,
  setIsLoading,
}: {
  children: React.ReactNode;
  onLoginSuccess: (data: {
    body: LoginWithIdpSchema;
  }) => Promise<LoginUserResponseBody>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) => {
  const { lang } = useLanguage();

  const router = useRouter();

  const handleResponse = (res: { user_type: UserType }) => {
    if (res.user_type === 'cannabis_consumer') {
      router.replace(`/${lang}/user/profile`);
      return;
    }

    if (res.user_type === 'doctor') {
      router.replace(`/${lang}/patients`);
      return;
    }
  };

  const { status } = useGoogleAuth({
    onLoginSuccess,
    handleResponse,
    isLoading,
    setIsLoading,
  });

  if (isLoading && status === 'loading') {
    return (
      <div className='flex items-center justify-center min-h-[550px]'>
        <Spinner size={'lg'} />
      </div>
    );
  }

  return <>{children}</>;
};
