import { StatusCodes } from 'http-status-codes';

export const errorCode = [
  'doctor_not_found',
  'email_not_match',
  'invitation_not_found',
  'invalid_token',
  'pathology_require_doctor',
  'error_sending_invitation_email',
  'cannot_delete_doctor_contact_book_item',
  'contact_book_item_already_exists',
  'not_found_contact_book_item',
  'invalid_query',
  'not_found_treatment',
  'not_found_patient',
  'invalid_body',
  'invalid_user_or_password',
  'username_already_exists',
  'not_found_user',
  'unauthorized',
  'test',
  'not_found_receiver',
  'invalid_x_user_type',
  'person_already_exists',
  'role_not_found',
  'invalid_params',
  'not_found_chat',
  'not_found_user_history',
  'invalid_end_date',
  'invalid_file_token',
  'not_found_medical_history',
  'invalid_tags',
  'invalid_oauth_token',
  'app_reached_user_limit',
  'treatment_has_user_history',
  'notification_not_found',
  'cannot_add_self_as_doctor',
  'not_found_pathology',
  'not_found_terms_and_conditions',
  'error_sending_forgot_password_email',
  'hash_error',
  'error_updating_password',
  'token_expired',
  'new_password_same_as_old',
  'limit_reached',
  'error_sending_message_to_sqs',
  'token_already_used',
  'not_found_form',
  'invalid_form_questions',
  'not_found_user_form',
  'password_and_hashed_password_must_be_provided',
] as const;

export type ErrorCode = (typeof errorCode)[number];

interface Error {
  code: string;
  status: number;
  description: string;
}

export const apiErrors: {
  key: ErrorCode;
  error: Error;
}[] = [
  {
    key: 'hash_error',
    error: {
      code: 'hash_error',
      status: StatusCodes.NOT_FOUND,
      description: 'hash_error',
    },
  },
  {
    key: 'error_updating_password',
    error: {
      code: 'error_updating_password',
      status: StatusCodes.NOT_FOUND,
      description: 'error_updating_password',
    },
  },
  {
    key: 'error_sending_forgot_password_email',
    error: {
      code: 'error_sending_forgot_password_email',
      status: StatusCodes.BAD_GATEWAY,
      description: 'Error sending email',
    },
  },
  {
    key: 'doctor_not_found',
    error: {
      code: 'doctor_not_found',
      status: StatusCodes.NOT_FOUND,
      description: 'Doctor not found',
    },
  },
  {
    key: 'email_not_match',
    error: {
      code: 'email_not_match',
      status: StatusCodes.BAD_REQUEST,
      description: 'Email not match',
    },
  },
  {
    key: 'invitation_not_found',
    error: {
      code: 'invitation_not_found',
      status: StatusCodes.NOT_FOUND,
      description: 'Invitation not found',
    },
  },
  {
    key: 'invalid_token',
    error: {
      code: 'invalid_token',
      status: StatusCodes.UNAUTHORIZED,
      description: 'Invalid token',
    },
  },
  {
    key: 'pathology_require_doctor',
    error: {
      code: 'pathology_require_doctor',
      status: StatusCodes.CONFLICT,
      description: 'Pathology require doctor',
    },
  },
  {
    key: 'not_found_pathology',
    error: {
      code: 'not_found_pathology',
      status: StatusCodes.NOT_FOUND,
      description: 'Pathology not found',
    },
  },
  {
    key: 'error_sending_invitation_email',
    error: {
      code: 'error_sending_invitation_email',
      status: StatusCodes.BAD_GATEWAY,
      description: 'Error sending invitation email',
    },
  },
  {
    key: 'cannot_add_self_as_doctor',
    error: {
      code: 'cannot_add_self_as_doctor',
      status: StatusCodes.CONFLICT,
      description: 'Cannot add self as doctor',
    },
  },
  {
    key: 'contact_book_item_already_exists',
    error: {
      code: 'contact_book_item_already_exists',
      status: StatusCodes.CONFLICT,
      description: 'Contact book item already exists',
    },
  },
  {
    key: 'not_found_contact_book_item',
    error: {
      code: 'not_found_contact_book_item',
      status: StatusCodes.NOT_FOUND,
      description: 'Contact book item not found',
    },
  },
  {
    key: 'notification_not_found',
    error: {
      code: 'notification_not_found',
      status: StatusCodes.NOT_FOUND,
      description: 'Notification not found',
    },
  },
  {
    key: 'treatment_has_user_history',
    error: {
      code: 'treatment_has_user_history',
      status: StatusCodes.CONFLICT,
      description: 'treatment already have user history items',
    },
  },
  {
    key: 'not_found_receiver',
    error: {
      code: 'not_found_receiver',
      status: StatusCodes.NOT_FOUND,
      description: 'The receiver not found',
    },
  },
  {
    key: 'app_reached_user_limit',
    error: {
      code: 'app_reached_user_limit',
      status: StatusCodes.CONFLICT,
      description: 'app_reached_user_limit',
    },
  },
  {
    key: 'not_found_patient',
    error: {
      code: 'not_found_patient',
      status: StatusCodes.NOT_FOUND,
      description: 'The target patient not found',
    },
  },
  {
    key: 'not_found_chat',
    error: {
      code: 'not_found_chat',
      status: StatusCodes.NOT_FOUND,
      description: 'The target chat not found',
    },
  },
  {
    key: 'invalid_oauth_token',
    error: {
      code: 'invalid_oauth_token',
      status: StatusCodes.UNAUTHORIZED,
      description: 'The access token provided is not valid',
    },
  },
  {
    key: 'invalid_tags',
    error: {
      code: 'invalid_tags',
      status: StatusCodes.BAD_REQUEST,
      description: 'Some of tagret tags not exists',
    },
  },
  {
    key: 'invalid_file_token',
    error: {
      code: 'invalid_file_token',
      status: StatusCodes.BAD_REQUEST,
      description: 'The file token provided is not valid',
    },
  },
  {
    key: 'invalid_end_date',
    error: {
      code: 'invalid_end_date',
      status: StatusCodes.BAD_REQUEST,
      description: 'end date must be greater than or equal to start date',
    },
  },
  {
    key: 'not_found_medical_history',
    error: {
      code: 'not_found_medical_history',
      status: StatusCodes.NOT_FOUND,
      description: 'not found medical history',
    },
  },
  {
    key: 'not_found_user_history',
    error: {
      code: 'not_found_user_history',
      status: StatusCodes.NOT_FOUND,
      description: 'not found user history',
    },
  },
  {
    key: 'invalid_params',
    error: {
      code: 'invalid_params',
      status: StatusCodes.BAD_REQUEST,
      description: 'invalid_params',
    },
  },
  {
    key: 'invalid_query',
    error: {
      code: 'invalid_query',
      status: StatusCodes.BAD_REQUEST,
      description: 'invalid query',
    },
  },
  {
    key: 'not_found_treatment',
    error: {
      code: 'not_found_treatment',
      status: StatusCodes.NOT_FOUND,
      description: 'Treatment not found',
    },
  },
  {
    key: 'invalid_user_or_password',
    error: {
      code: 'invalid_user_or_password',
      status: StatusCodes.FORBIDDEN,
      description: 'User or password is invalid',
    },
  },
  {
    key: 'invalid_body',
    error: {
      code: 'invalid_body',
      status: StatusCodes.BAD_REQUEST,
      description: 'Invalid body',
    },
  },
  {
    key: 'not_found_user',
    error: {
      code: 'not_found_user',
      status: StatusCodes.NOT_FOUND,
      description: 'Not found target user',
    },
  },
  {
    key: 'invalid_x_user_type',
    error: {
      code: 'invalid_x_user_type',
      status: StatusCodes.FORBIDDEN,
      description: 'Invalid x_user_type',
    },
  },
  {
    key: 'cannot_delete_doctor_contact_book_item',
    error: {
      code: 'cannot_delete_doctor_contact_book_item',
      status: StatusCodes.CONFLICT,
      description: 'Cannot delete doctor contact book item',
    },
  },
  {
    key: 'person_already_exists',
    error: {
      code: 'person_already_exists',
      status: StatusCodes.CONFLICT,
      description: 'Person email already exists',
    },
  },
  {
    key: 'username_already_exists',
    error: {
      code: 'username_already_exists',
      status: StatusCodes.CONFLICT,
      description: 'Person Username already exists',
    },
  },
  {
    key: 'role_not_found',
    error: {
      code: 'role_not_found',
      status: StatusCodes.NOT_FOUND,
      description: 'Target role not found',
    },
  },
  {
    key: 'unauthorized',
    error: {
      code: 'unauthorized',
      status: StatusCodes.UNAUTHORIZED,
      description: 'unauthorized session',
    },
  },
  {
    key: 'not_found_terms_and_conditions',
    error: {
      code: 'not_found_terms_and_conditions',
      status: StatusCodes.NOT_FOUND,
      description: 'not found terms and conditions',
    },
  },
  {
    key: 'token_expired',
    error: {
      code: 'token_expired',
      status: StatusCodes.UNAUTHORIZED,
      description: 'The token is expired',
    },
  },
  {
    key: 'new_password_same_as_old',
    error: {
      code: 'new_password_same_as_old',
      status: StatusCodes.UNAUTHORIZED,
      description: 'The new password must be different from the old one.',
    },
  },
  {
    key: 'limit_reached',
    error: {
      code: 'limit_reached',
      status: StatusCodes.TOO_MANY_REQUESTS,
      description:
        'You have reached the maximum request limit. Please wait 24 hours to request again.',
    },
  },
  {
    key: 'error_sending_message_to_sqs',
    error: {
      code: 'error_sending_message_to_sqs',
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      description: 'error sending message to sqs',
    },
  },
  {
    key: 'token_already_used',
    error: {
      code: 'token_already_used',
      status: StatusCodes.BAD_REQUEST,
      description: 'token already used',
    },
  },
  {
    key: 'not_found_form',
    error: {
      code: 'not_found_form',
      description: 'Form not found',
      status: StatusCodes.NOT_FOUND,
    },
  },
  {
    key: 'invalid_form_questions',
    error: {
      code: 'invalid_form_questions',
      description: 'Invalid form questions',
      status: StatusCodes.BAD_REQUEST,
    },
  },
  {
    key: 'not_found_user_form',
    error: {
      code: 'not_found_user_form',
      description: 'User form not found',
      status: StatusCodes.NOT_FOUND,
    },
  },
  {
    key: 'password_and_hashed_password_must_be_provided',
    error: {
      code: 'Password_and_hashed_password_must_be_provided',
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      description: 'Password and hashed password must be provided.',
    },
  },
];

export const buildError = (error: ErrorCode): Error => {
  return apiErrors.find((err) => err.key === error)?.error!;
};

export const requiredFieldMessage = 'Campo requerido';
