import * as z from 'zod';
import { identityProviders } from '../constants/providers.constants';
import { UserModel } from '../models/user.model';
import { UserType } from 'database';
import { AppSuccessData } from './success.schema';
import { emailSchema } from './email.schema';

export const loginWithUserAndPassword = z.object({
  email: emailSchema,
  password: z.string(),
});

export type LoginWithUserAndPasswordSchema = z.infer<
  typeof loginWithUserAndPassword
>;

export const updateUserPasswordSchema = z
  .object({
    old_password: z.string(),
    new_password: z.string(),
    new_password_confirmation: z.string(),
  })
  .refine(
    ({ new_password, new_password_confirmation, old_password }) => {
      if (old_password === new_password) {
        return false;
      }

      if (new_password !== new_password_confirmation) {
        return false;
      }

      return true;
    },
    {
      message: 'Passwords do not match',
      path: ['new_password'],
    },
  );

export type UpdateUserPasswordRequestBody = z.infer<
  typeof updateUserPasswordSchema
>;

export type UpdateUserPasswordResponseBody = AppSuccessData;

export const loginWithIdpSchema = z.object({
  provider: z.enum(identityProviders),
  access_token: z.string().min(1),
});

export type LoginWithIdpSchema = z.infer<typeof loginWithIdpSchema>;

export const registerUserByIdp = loginWithIdpSchema.extend({
  user_type: z.nativeEnum(UserType),
});

export type RegisterUserByIdp = z.infer<typeof registerUserByIdp>;

export const accessTokenSchema = z.object({
  sub: z.number().min(1),
});

export type SessionAccessToken = z.infer<typeof accessTokenSchema>;

export const registerUserSchema = loginWithUserAndPassword.extend({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  username: z.string().min(1),
  user_type: z.nativeEnum(UserType),
  provider: z.enum(['local', 'google']).optional(),
});

export type RegisterUserSchema = z.infer<typeof registerUserSchema>;

export const registerUserResponseBody = z.object({
  access_token: z.string().min(1),
  user_type: z.nativeEnum(UserType),
});

export type RegisterUserResponseBody = z.infer<typeof registerUserResponseBody>;

export type AuthInfoResponseBody = UserModel & {
  person_missing_fields: {
    isMissing: boolean;
    missing_fields: string[];
  };
};

export const loginUserResponseBody = z.object({
  access_token: z.string().min(1),
  user_type: z.nativeEnum(UserType),
});

export type LoginUserResponseBody = z.infer<typeof loginUserResponseBody>;

export const registerUserByInvitationRequestBody = z.discriminatedUnion(
  'strategy',
  [
    registerUserByIdp.extend({
      strategy: z.literal('idp'),
      token: z.string(),
    }),
    registerUserSchema.extend({
      strategy: z.literal('local'),
      token: z.string(),
    }),
  ],
);

export type RegisterUserByInvitationRequestBody = z.infer<
  typeof registerUserByInvitationRequestBody
>;

export const deleteUserAccountSchema = z.object({
  confirm: z.literal('delete account'),
});

export type DeleteUserAccountSchema = z.infer<typeof deleteUserAccountSchema>;

export const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

export type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

export const resetPasswordSchema = z
  .object({
    token: z.string().min(1), // Token generado para resetear la contraseña
    newPassword: z.string().min(8), // Contraseña nueva
    confirmPassword: z.string().min(8), // Confirmación de la contraseña
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export const resetPasswordTokenSchema = z.object({
  token_id: z.number().min(1),
  exp: z.number().optional(),
});

export type ResetPasswordTokenSchema = z.infer<typeof resetPasswordTokenSchema>;
